import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LogoutButton.css'; // Import the CSS file for styling

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button className="learn-more" onClick={() => logout({ returnTo: window.location.origin })}>
      <span className="circle" aria-hidden="true">
        <span className="icon arrow"></span>
      </span>
      <span className="button-text">Log Out</span>
    </button>
  );
};

export default LogoutButton;
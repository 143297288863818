import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Ravitsemusvalmennus() {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Ravitsemusvalmennus</h1>
          <p>Get personalized nutrition coaching to achieve your health goals.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Ravitsemusvalmennus;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function WeeklyMenu() {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Weekly Menu</h1>
          <p>Check out our weekly menu and plan your meals ahead.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default WeeklyMenu;
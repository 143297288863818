import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SelectImg from '../../assets/images/select.png'; // Replace with the correct path
import CookImg from '../../assets/images/Logo/logo.png'; // Replace with the correct path
import EnjoyImg from '../../assets/images/enjoy.png'; // Replace with the correct path
import './FeaturesSection.css'; // Import the CSS file for additional styling

function FeaturesSection() {
  const features = [
    {
      title: 'Valitse',
      description: 'Valitse ruokalista ja ruokalaji. Joka viikko uusia reseptejä kokeilta ja ravitsemusasiantuntijoilta.',
      imgSrc: SelectImg,
    },
    {
      title: 'Kokkaa',
      description: 'Ensiluokkaiset ainesosamme ovat valmiiksi mitoitettuja ja valmistettuja. Seuraa helppoja vaiheittaisia ohjeitamme, mukaan lukien valokuvat.',
      imgSrc: CookImg,
    },
    {
      title: 'Nauti',
      description: 'Kokin kuratoimat reseptimme helpottavat uusien makujen maistamista samalla, kun pysyt elämäntapatavoitteidesi mukana.',
      imgSrc: EnjoyImg,
    },
  ];

  const SectionHeading = "Ateriasi on vain kolmen askeleen päässä";
  const SectionSubheading = "Helppo tapa nauttia herkullisista ja terveellisistä kotiruoista!";

  return (
    <Container className="py-5">
      <h2 className="text-center mb-4">{SectionHeading}</h2>
      <h5 className="text-center mb-2 text-muted">{SectionSubheading}</h5>
      <Container className="justify-content-center my-5">
        <Row className="g-4 py-5 row-cols-1 row-cols-lg-3">
          {features.map((feature, index) => (
            <Col key={index} className="feature col text-center">
              <div className="feature-icon  mb-3">
                <img src={feature.imgSrc} alt={feature.title} className="bi" width="120" height="120" />
              </div>
              <h2 className="h5">{feature.title}</h2>
              <p className="text-black">{feature.description}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default FeaturesSection;
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import AdminPage from "./pages/AdminPage";
import WeeklyMenuPage from "./pages/WeeklyMenuPage";
import RuokaohjelmaPage from "./pages/RuokaohjelmaPage";
import HowItWorksPage from "./pages/HowItWorksPage";
import LahjakoritPage from "./pages/LahjakoritPage";
import RavitsemusvalmennusPage from "./pages/RavitsemusvalmennusPage";
import TietojaPage from "./pages/TietojaPage";
import AppNavbar from "./components/Navbar/Navbar";
import Loading from "./components/Loading/Loading";
import ProtectedRoute from "./components/ProtectedRoute";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./components/Footer/Footer";
import "./App.css"; // Import the CSS file for transitions

function App() {
  const { isLoading } = useAuth0();
  const location = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -50,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };

  // Determine if the navbar should be shown based on the current route
  const shouldShowNavbar = location.pathname !== "/ruokaohjelma";

  return (
    <div className="App">
      {shouldShowNavbar && <AppNavbar />}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <HomePage />
              </motion.div>
            }
          />
          <Route
            path="/profile"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <ProtectedRoute component={ProfilePage} />
              </motion.div>
            }
          />
          <Route
            path="/admin"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <ProtectedRoute component={AdminPage} roles={["admin"]} />
              </motion.div>
            }
          />
          <Route
            path="/weekly-menu"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <WeeklyMenuPage />
              </motion.div>
            }
          />
          <Route
            path="/ruokaohjelma"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <RuokaohjelmaPage />
              </motion.div>
            }
          />
          <Route
            path="/how-it-works"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <HowItWorksPage />
              </motion.div>
            }
          />
          <Route
            path="/lahjakorit"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <LahjakoritPage />
              </motion.div>
            }
          />
          <Route
            path="/ravitsemusvalmennus"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <RavitsemusvalmennusPage />
              </motion.div>
            }
          />
          <Route
            path="/tietoja"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <TietojaPage />
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Card, ListGroup } from "react-bootstrap";
import boxesImgs from "../../../assets/images/pics/foodboxes.webp";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const DeliveryStep = ({ userData, onStepValidation, stepData }) => {
  const { selectedCategories, deliveryData, totalServings, pricePerServing, totalPrice } = stepData;
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const img = boxesImgs;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    streetAddress: "",
    aptSuiteFloor: "",
    city: "",
    zipCode: "",
    state: "",
    phoneNumber: "",
  });

  useEffect(() => {
    if (!isAuthenticated) {
      // Redirect to signup page if user is not authenticated
      navigate("/ruokaohjelma?step=1");
      return;
    }

    if (user) {
      setFormData((prevData) => ({
        ...prevData,
        email: user.email || "",
        firstName: user.name || "",
      }));
    }

    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        ...userData,
      }));
    }

    if (deliveryData) {
      setFormData((prevData) => ({
        ...prevData,
        ...deliveryData,
      }));
    }
  }, [user, userData, deliveryData, isAuthenticated, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = Object.values(formData).every((field) => field.trim() !== "");
    onStepValidation(isValid);

    if (isValid) {
      console.log("Form data is valid:", formData);
    } else {
      console.error("Form validation failed. Some fields are missing.");
    }
  };

  return (
    <Container className="my-4">
      <Row>
        <Col md={8}>
          {/* Delivery Address Form */}
          <Card className="mb-4 shadow-sm border-0 rounded-lg">
            <Card.Header className="bg-light">
              <h5 className="mb-0">Delivery Address</h5>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>First Name *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formLastName">
                      <Form.Label>Last Name *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    disabled
                  />
                </Form.Group>
                <Form.Group controlId="formStreetAddress" className="mb-3">
                  <Form.Label>Street Address *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter street address"
                    name="streetAddress"
                    value={formData.streetAddress}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formAptSuiteFloor" className="mb-3">
                  <Form.Label>Apt, Suite, Floor</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Apt, suite, floor"
                    name="aptSuiteFloor"
                    value={formData.aptSuiteFloor}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formCity" className="mb-3">
                  <Form.Label>City *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formZipCode" className="mb-3">
                  <Form.Label>Zip Code *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter zip code"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formState" className="mb-3">
                  <Form.Label>State *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPhoneNumber" className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter phone number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  Continue
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          {/* Order Summary */}
          <Card border="0" className="mb-4 shadow-sm rounded-lg">
            <Card.Header className="bg-light d-flex justify-content-between align-items-center p-3">
              <h5 className="mb-0">Order Summary</h5>
              <span className="badge bg-warning text-black">
                <small>Total Servings</small> {totalServings || "N/A"}
              </span>
            </Card.Header>
            <Card.Body className="p-4">
              {/* Meal Details */}
              <div className="text-center mb-3">
                <img
                  src={img}
                  alt="Food boxes"
                  className="img-fluid rounded-circle mb-2"
                  width="100"
                />
                <p className="fw-bold">
                  <span className="text-danger">{stepData.selectedMeals || "N/A"}</span> meals for
                  <span className="text-danger"> {stepData.selectedPeople || "N/A"}</span> people
                </p>
              </div>

              {/* Categories with Icons */}
              <ListGroup variant="flush" className="mb-4">
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {selectedCategories && selectedCategories.length > 0 ? (
                      <span>
                        {selectedCategories.map((category, index) => (
                          <span key={index} className="badge bg-warning me-1">
                            {category.name}
                          </span>
                        ))}
                      </span>
                    ) : (
                      "No selection"
                    )}
                  </div>
                </ListGroup.Item>

                {/* Additional Order Details */}
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <span>Total servings</span>
                  <strong>{totalServings || "N/A"}</strong>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <span>Price per serving <small>(incl. VAT)</small></span>
                  <strong>€{pricePerServing || "N/A"}</strong>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <span>Total Box price <small>(incl. VAT)</small></span>
                  <strong>€{totalPrice || "N/A"}</strong>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <p className="text-success fw-bolder">Free delivery!</p>
                </ListGroup.Item>
              </ListGroup>

              {/* Discount Code Form */}
              <Form className="mt-3">
                <div className="input-group">
                  <Form.Control type="text" placeholder="Discount code" />
                  <Button variant="secondary" type="button" className="ms-2">
                    Apply
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryStep;

import React from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import backgroundImage from '../assets/images/backgrounds/girl-biting-slice-cucumber.jpg'; // Import a different background image
import anotherLogo from '../assets/images/Logo/chefboksi_logo_ilman_taustaa.png'; // Import a different logo image
import HowItWorksStep from '../components/HowItWorksStep';
import RecycleSection from '../components/RecycleSection/RecycleSection';
import FAQSection from '../components/FAQSection/FAQSection';
function HowItWorks() {
  return (
    <>
       <HeroSection
        heading="How It Works"
        subheading="Learn how our service works and how you can benefit from it."
        backgroundImage={backgroundImage}
        logo={anotherLogo}
        logoWidth={150} // Set the logo width
        showButton={false}
        showLogo={true}
      />
  
       <HowItWorksStep 
        
      />
      <RecycleSection />
      <FAQSection />
     
    </>
  );
}

export default HowItWorks;
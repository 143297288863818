import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import step1Image from '../assets/images/backgrounds/Build Your Plan.jpeg';
import step2Image from '../assets/images/backgrounds/person_Selecting_Customizing_M.jpeg';
import step3Image from '../assets/images/backgrounds/Build Your Plan.jpeg';
import step4Image from '../assets/images/backgrounds/Receive Your Box, Cook & Enjoy.webp';
import step5Image from '../assets/images/backgrounds/Choose Green Market Items to Eat Healthy All Day.jpeg';

function HowItWorksStep() {
  return (
    <>
      <style>
        {`
          p {
            font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          }
          h1, h2 {
            font-family: Cambria, Georgia, serif;
          }
          .dotfa {
            font-size: 5px;
          }
          .btn {
            background-color: #ff8000;
            color: white;
          }
          .btn:hover {
            background-color: white;
            color: #ff8000;
          }
        `}
      </style>

      {/* Step 1 */}
      <section className="container mt-5">
        <div className="row gx-lg-5 mb-3 align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img src={step1Image} className="w-100 shadow-4-strong rounded-4 mb-4" alt="Step 1" />
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <h3 className="fw-bold">1. Valitse erityisruokavaliosi!</h3>
            <div className="mb-2 small" style={{ color: '#ff8000' }}>
              <i className="bi bi-book me-2"></i><span>Suunnittele ruokavaliosi</span>
            </div>
            <p className="text-black">
              <i className="bi bi-circle-fill me-2 dotfa text-dark"></i> Selaa erityisruokavalioille suunniteltuja ruokaboksejamme.
            </p>
            <a className="btn btn-custom btn-md btn-block my-2 mx-2 btn-rounded" href="#step1" role="button">aloita</a>
          </div>
        </div>
      </section>

      {/* Step 2 */}
      <section className="container mt-5">
        <div className="row gx-lg-5 mb-3 flex-lg-row-reverse align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img src={step2Image} className="w-100 shadow-4-strong rounded-4 mb-4" alt="Step 2" />
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <h3 className="fw-bold">2. Räätälöi tilauksesi</h3>
            <div className="mb-2 small" style={{ color: '#ff8000' }}>
              <i className="bi bi-hand-index-thumb me-2"></i><span>Muokkaa vapaasti tilaustasi!</span>
            </div>
            <p className="text-black">
              <i className="bi bi-circle-fill me-2 dotfa"></i> Mukauta ruokaboksisi omien mieltymystesi ja ruokarajoitteidesi mukaan.
            </p>
            <a className="btn btn-custom btn-md btn-block my-2 mx-2 btn-rounded" href="#step2" role="button">aloita</a>
          </div>
        </div>
      </section>

      {/* Step 3 */}
      <section className="container mt-5">
        <div className="row gx-lg-5 mb-3 align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img src={step3Image} className="w-100 shadow-4-strong rounded-4 mb-4" alt="Step 3" />
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <h3 className="fw-bold">3. Tilaa</h3>
            <div className="mb-2 small" style={{ color: '#ff8000' }}>
              <i className="bi bi-shop me-2"></i><span>Tilaa helposti verkkokaupastamme</span>
            </div>
            <p className="text-black">
              <i className="bi bi-circle-fill me-2 dotfa"></i> Tilaa ruokaboksi helposti verkkosivuiltamme, ilmaisella kotiinkuljetuksella.
            </p>
            <a className="btn btn-custom btn-md btn-block my-2 mx-2 btn-rounded" href="#step3" role="button">aloita</a>
          </div>
        </div>
      </section>

      {/* Step 4 */}
      <section className="container mt-5">
        <div className="row gx-lg-5 mb-3 flex-lg-row-reverse align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img src={step4Image} className="w-100 shadow-4-strong rounded-4 mb-4" alt="Step 4" />
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <h3 className="fw-bold">4. Nauti huolella valituista raaka-aineista ja resepteistä</h3>
            <div className="mb-2 small" style={{ color: '#ff8000' }}>
              <i className="bi bi-box-seam me-2"></i><span>Laadukkaat raaka-aineet</span>
            </div>
            <p className="text-black">
              <i className="bi bi-circle-fill me-2 dotfa"></i> Saat Chefboksin mukana tuoreet ja laadukkaat raaka-aineet sekä selkeät reseptit.
            </p>
            <a className="btn btn-custom btn-md btn-block my-2 mx-2 btn-rounded" href="#step4" role="button">aloita</a>
          </div>
        </div>
      </section>

      {/* Step 5 */}
      <section className="container mt-5">
        <div className="row gx-lg-5 mb-3 align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img src={step5Image} className="w-100 shadow-4-strong rounded-4 mb-4" alt="Step 5" />
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <h3 className="fw-bold">5. Valitse terveellisiä markkinatuotteita</h3>
            <div className="mb-2 small" style={{ color: '#ff8000' }}>
              <i className="bi bi-shop-window me-2"></i><span>Green Market -valinnat</span>
            </div>
            <p className="text-black">
              <i className="bi bi-circle-fill me-2 dotfa"></i> Tutustu Green Market -tuotteisiin ja lisää terveellisiä vaihtoehtoja ruokavalioosi.
            </p>
            <a className="btn btn-custom btn-md btn-block my-2 mx-2 btn-rounded" href="#step5" role="button">aloita</a>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowItWorksStep;

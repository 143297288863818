import React from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import FeaturesSection from '../components/FeaturesSection/FeaturesSection';
import CardCarousel from '../components/CardCarousel/CardCarousel';
import backgroundImage from '../assets/images/backgrounds/backgroundfullhd.jpg'; // Import the background image
import logo from '../assets/images/Logo/logo.png'; // Import the logo image

function HomePage() {
  return (
    <div className="HomePage">
      <HeroSection 
        heading="Paras tapa valmistaa ruokaa kotona " 
        subheading="Tutustu palveluihimme ja löydä itsellesi sopiva ravitsemusohjelma."
        backgroundImage={backgroundImage}
        logo={logo}
      />
      <FeaturesSection />
      <CardCarousel 
         SectionHeading = "Discover Our New Meal Plans"
        SectionSubheading = "Explore a variety of meal plans that suit your taste, lifestyle, and nutrition goals."
      />
    </div>
  );
}

export default HomePage;
import React, { useEffect, useState } from "react";
 
/**
 * PricingSection Component
 * This component calculates and displays pricing information based on selected meals and people.
 * It takes selectedMeals, selectedPeople as props and notifies the parent component of price updates.
 *
 * @param {number} selectedMeals - The number of meals selected.
 * @param {number} selectedPeople - The number of people selected.
 * @param {function} onPricingUpdate - Callback function to notify parent about pricing updates.
 */
const PricingSection = ({ selectedMeals, selectedPeople, onPricingUpdate }) => {
  const pricePerServing = 11.2; // Price per serving in dollars
  const totalServings = selectedMeals * selectedPeople; // Total servings calculated
  const firstBoxTotal = (totalServings * pricePerServing).toFixed(2); // Total cost formatted to two decimal places
  const [discount, setDiscount] = useState(0); // State to hold any applied discount

  // Calculate total price after discount
  const totalWithDiscount = (firstBoxTotal - discount).toFixed(2);

  useEffect(() => {
    // Notify parent of the calculated servings and total price
    if (onPricingUpdate) {
      onPricingUpdate(totalServings, totalWithDiscount);
    }
  }, [totalServings, totalWithDiscount, onPricingUpdate]);

  // Handle discount calculation based on the number of people
  const handleDiscount = () => {
    if (selectedPeople > 4) {
      setDiscount(5); // $5 discount for groups larger than 4
    } else {
      setDiscount(0); // No discount for smaller groups
    }
  };

  // Use effect to update discount whenever selectedPeople changes
  useEffect(() => {
    handleDiscount();
  }, [selectedPeople]);

  return (
    <>
      
        <div className="text-start mb-2">
          <p className="text-muted mb-1">
            <span className="fw-bolder">{selectedMeals}</span> meals for{" "}
            <span className="fw-bolder">{selectedPeople}</span> people per week
          </p>
          <p className="text-muted fw-bold mb-0">
            {totalServings} total servings
          </p>
        </div>

        <div className="d-flex justify-content-between mb-3 border-bottom pb-3">
          <p className="mb-0 fw-medium">Price per Serving:</p>
          <h5 className="mb-0 fw-bold">${pricePerServing.toFixed(2)}</h5>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-2">
          <span className="fw-bold">First Box Total:</span>
          <h4 className="mb-0 animated-price">${totalWithDiscount}</h4>
        </div>

        {/* Conditional rendering for discount information */}
        {discount > 0 && (
          <div className="text-success fw-bold">
            <p>You received a discount of <span className="text-danger">${discount}</span>!</p>
          </div>
        )}

        <div className="text-muted small">(Includes 14% VAT)</div>
    
    </>
  );
};

export default PricingSection;

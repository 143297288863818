// ToastNotification.js
import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const ToastNotification = ({ message, show, onClose, variant }) => {
  return (
    <ToastContainer position="top-end">
      <Toast show={show} onClose={onClose} bg={variant} className="m-2">
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastNotification;

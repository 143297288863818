import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AdminPage = () => {
  const { user, isAuthenticated } = useAuth0();
  const roles = (user && user['http://localhost:3000/roles']) || [];

  if (!isAuthenticated) {
    return <div>Please log in to view this page.</div>;
  }

  if (!roles.includes('admin')) {
    return <div>You do not have access to this page.</div>;
  }

  return (
    <div>
      <h2>Admin Page</h2>
      <p>Welcome, {user.name}. You have admin access.</p>
    </div>
  );
};

export default AdminPage;
import React from 'react';
import './HeroSection.css'; // Import the CSS file for styling
import StartButton from '../StartButton/StartButton';

function HeroSection({ heading, subheading, backgroundImage, logo, logoWidth = 72, logoHeight = 72, showButton = true, showLogo = false }) {
  return (
    <div className="hero-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="hero-overlay">
        <div className="text-container px-5 py-5 my-5 mx-5  text-center">
          {showLogo && (
            <img className="d-block mx-auto mb-4" src={logo} alt="Logo" width={logoWidth} height={logoHeight} />
          )}
          <h1 className="display-5 fw-bold text-white">{heading}</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4 text-white">{subheading}</p>
            {showButton && (
              <div className="d-flex justify-content-center">
                <StartButton text="Aloita" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
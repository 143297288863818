import React, { useEffect } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./CategoryCards.css"; // Ensure to create and style this CSS file

// Define categories with name, description, and icon
const categories = [
  { name: "Gluteeniton", description: "Delicious gluten-free options for those with sensitivities.", icon: "bi-egg" },
  { name: "Kasvipohjainen", description: "Plant-based meals full of flavor and nutrition.", icon: "bi-tree" },
  { name: "Runsasproteiini", description: "High-protein meals to keep you energized and full.", icon: "bi-basket" },
  { name: "Keto", description: "Keto-friendly options to support a low-carb lifestyle.", icon: "bi-check-circle" },
  { name: "Nopea ja Tervellistä", description: "Quick and healthy meals for a balanced diet.", icon: "bi-hourglass-split" },
  { name: "Välimerellistä", description: "Mediterranean-inspired dishes bursting with flavor.", icon: "bi-geo-alt" },
];

const CategoryCards = ({ selectedCategories, onSelectCategories }) => {
  
  // Handle category selection/deselection
  const handleCardClick = (category) => {
    if (selectedCategories.some((cat) => cat.name === category.name)) {
      onSelectCategories(selectedCategories.filter((cat) => cat.name !== category.name));
    } else {
      onSelectCategories([...selectedCategories, category]);
    }
  };

  // Check if a category is selected
  const isSelected = (category) => selectedCategories.some((cat) => cat.name === category.name);

  // Render tooltip for a given category
  const renderTooltip = (props, category) => (
    <Tooltip id="button-tooltip" {...props}>
      {category.description}
    </Tooltip>
  );

  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-sm-2 g-2">
      {categories.map((category, index) => (
        <div key={index} className="col">
          <OverlayTrigger
            placement="top" // Tooltip position (top, bottom, left, right)
            delay={{ show: 250, hide: 400 }} // Delay for showing and hiding the tooltip
            overlay={(props) => renderTooltip(props, category)} // Render tooltip with the category description
          >
            <div
              className={`card border h-100 text-center category-card ${isSelected(category) ? "selected-card" : ""}`}
              onClick={() => handleCardClick(category)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.key === "Enter" && handleCardClick(category)}
              aria-pressed={isSelected(category)}
              aria-label={`Select ${category.name}`}
            >
              <div className="card-body d-flex flex-column align-items-center position-relative">
                {/* Render checked icon if the category is selected */}
                {isSelected(category) && (
                  <i className="bi bi-check-circle checked-icon"></i>
                )}
                <i className={`bi ${category.icon} category-icon mb-3`}></i>
                <h5 className="card-title fw-bolder">{category.name}</h5>
              </div>
            </div>
          </OverlayTrigger>
        </div>
      ))}
    </div>
  );
};

export default CategoryCards;

// src/components/CustomButton.js
import React from 'react';
import './StartButton.css'; // Import the CSS file for styling

function StartButton({ text, onClick }) {

    

  return (
    <button className="learn-more" onClick={onClick}>
      <span className="circle" aria-hidden="true">
        <span className="icon arrow"></span>
      </span>
      <span className="button-text text-white">{text}</span>
    </button>
  );
}

export default StartButton;

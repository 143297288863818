import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LoginButton.css'; // Import the CSS file for styling

const LoginButton = ({ text = "Log In / Sign Up" }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button className="learn-more" onClick={() => loginWithRedirect()}>
      <span className="circle" aria-hidden="true">
        <span className="icon arrow"></span>
      </span>
      <span className="button-text">{text}</span>
    </button>
  );
};

export default LoginButton;

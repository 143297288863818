import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import PlanSelectionStep from "./PlanSelectionStep/PlanSelectionStep";
import SignupStep from "./SignupStep/SignupStep";
import DeliveryStep from "./DeliveryStep/DeliveryStep";
import ToastNotification from "./ToastNotification";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading/Loading";

const steps = [
  { label: "Select Plan", icon: "bi-calendar-check" },
  { label: "Signup", icon: "bi-person-plus" },
  { label: "Delivery", icon: "bi-truck" },
  { label: "Payment", icon: "bi-credit-card" },
  { label: "Meals", icon: "bi-list-ul" },
];

const MealPlanStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [stepData, setStepData] = useState({
    selectedCategories: [],
    userData: {},
  });
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("danger");

  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const stepFromURL = params.get("step");
    console.log("Step from URL:", stepFromURL);

    if (stepFromURL) {
      const parsedStep = parseInt(stepFromURL, 10);
      if (!isNaN(parsedStep) && parsedStep >= 0 && parsedStep < steps.length) {
        setActiveStep(parsedStep);
      }
    }
  }, [location]);

  useEffect(() => {
    console.log("Auth0 isAuthenticated:", isAuthenticated);
    console.log("Auth0 user:", user);

    if (isAuthenticated && user) {
      setStepData((prev) => ({
        ...prev,
        userData: {
          email: user.email || prev.userData.email,
          name: user.name || prev.userData.name,
        },
      }));

      const redirectStep = localStorage.getItem("redirectStep");
      console.log("Redirect step:", redirectStep);
      if (redirectStep) {
        localStorage.removeItem("redirectStep");
        navigate(`/ruokaohjelma?step=${redirectStep}`);
      } else {
        navigate('/ruokaohjelma');
      }
    }
  }, [isAuthenticated, user, navigate]);

  useEffect(() => {
    console.log("Step data saved to local storage:", stepData);
    localStorage.setItem("stepData", JSON.stringify(stepData));
  }, [stepData]);

  const updateStepInURL = (step) => {
    const params = new URLSearchParams(window.location.search);
    params.set("step", step);
    navigate(`${window.location.pathname}?${params.toString()}`, {
      replace: true,
    });
    console.log("Updated URL:", `${window.location.pathname}?${params.toString()}`);
  };

  const handleNext = () => {
    console.log("Handling next step. Current step:", activeStep);

    if (activeStep === 0 && (!stepData.selectedCategories || stepData.selectedCategories.length === 0)) {
      setToastMessage("Please select a plan before proceeding.");
      setToastVariant("warning");
      setShowToast(true);
      return;
    }

    if (activeStep === 1 && !isAuthenticated) {
      localStorage.setItem("redirectStep", activeStep);
      loginWithRedirect();
      return;
    }

    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    updateStepInURL(nextStep);
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    console.log("Handling back step. Previous step:", prevStep);
    setActiveStep(prevStep);
    updateStepInURL(prevStep);
  };

  const handleStepData = (data) => {
    console.log("Handling step data:", data);
    setStepData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const handleStepValidation = (isValid) => {
    console.log("Step validation:", isValid);
  };

  const getStepContent = (step) => {
    console.log("Getting content for step:", step);
    switch (step) {
      case 0:
        return <PlanSelectionStep onStepData={handleStepData} />;
      case 1:
        return <SignupStep stepData={stepData} updateStepInURL={updateStepInURL} />;
      case 2:
        return <DeliveryStep onStepValidation={handleStepValidation} stepData={stepData} user={stepData.userData} />;
      case 3:
        return "Step 4: Payment Details";
      case 4:
        return "Step 5: Select Your Meals";
      default:
        return "Unknown step";
    }
  };

  return (
    <Box className="container-fluid stepper-container my-2">
      <Stepper className="mb-4" activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={() => (
                <i
                  className={`bi ${step.icon} fs-3`}
                  style={{ color: activeStep >= index ? "#ff8000" : "grey" }}
                ></i>
              )}
              sx={{
                "& .MuiStepLabel-label": {
                  color: activeStep >= index ? "#ff8000" : "grey",
                  fontWeight: activeStep >= index ? "bold" : "normal",
                },
                "& .MuiStepConnector-line": {
                  backgroundColor: "#ff8000",
                },
              }}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box className="stepper-content border-bottom border-3">
        {activeStep === steps.length ? (
          <Box className="text-center">
            <Typography variant="h6" className="mb-4">
              All steps completed! You can now proceed.
            </Typography>
          </Box>
        ) : (
          <Box>
            {loading ? (
              <Box className="text-center p-5">
                <Loading loading={loading} />
              </Box>
            ) : (
              <Typography className="container">
                {getStepContent(activeStep)}
              </Typography>
            )}
            <Box className="stepper-buttons text-center mt-4">
              <Button
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
                variant="outlined"
                color="primary"
                className="mx-2"
              >
                Back
              </Button>
              <Button
                onClick={handleNext}
                variant="outlined"
                color="primary"
                className="mx-2"
                disabled={loading}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <ToastNotification
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
        variant={toastVariant}
      />
    </Box>
  );
};

export default MealPlanStepper;

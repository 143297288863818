import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import BackgroundImg from '../../assets/images/backgrounds/backgroundRecycle.png';

function RecycleSection() {
  return (
    <section className="overflow-hidden">
      <div className="p-5 text-center bg-image" 
           style={{ 
             backgroundImage: `url(${BackgroundImg})`, 
             height: '500px', 
             backgroundSize: 'cover', 
             backgroundPosition: 'center' 
           }}>
        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-lg-10">
                <div className="container text-center my-5">
                  <h1 className="mb-5 display-3 text-black">Muista kierrättää</h1>
                  <h4 className="text-black">Voit myös jättää tyhjän boksin kuljettajallemme kierrätettäväksi.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RecycleSection;
import React, { useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../Loading/Loading";
import { useNavigate } from "react-router-dom";
import './SignupStep.css';

function SignupStep({ stepData }) {
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Checking authentication state...");
    console.log("isAuthenticated:", isAuthenticated);
    console.log("user:", user);

    if (isAuthenticated && user) {
      console.log("Navigating to /ruokaohjelma?step=2");
      navigate('/ruokaohjelma?step=2');
    }
  }, [isAuthenticated, user, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <Row className="align-items-center g-lg-5">
        <Col lg={7} md={12} className="text-center text-lg-start mb-4 mb-lg-0">
          <h1 className="display-4 lh-1 mb-3">Unlock a World of Flavor!</h1>
          <p className="fs-6 lead">
            Sign up today and discover the convenience of personalized meal plans tailored just for you! 
            Enjoy delicious recipes, fresh ingredients, and save time in the kitchen.
          </p>
        </Col>
        <Col lg={5} md={12}>
          <Card className="border border-2 shadow rounded-3 bg-light">
            <Card.Header className="text-center">
              <h6 className="lead">Get Started with Your Meal Plan</h6>
            </Card.Header>
            <Card.Body className="p-4">
              {isAuthenticated && user && (
                <div className="mb-4 text-center">
                  <h5 className="mb-3">Welcome, {user.name || "User"}!</h5>
                  <p className="text-muted">Your email: {user.email}</p>
                </div>
              )}
              <p className="text-muted fs-6 lead text-end border-bottom">Your Category Preference</p>
              {stepData.selectedCategories.length > 0 ? (
                <div className="list-group">
                  {stepData.selectedCategories.map((category, index) => (
                    <div key={index} className="list-group-item d-flex align-items-center border-0 bg-transparent">
                      <i className={`bi me-2 ${category.icon}`} style={{ fontSize: '1.2rem' }} />
                      <span className="w-100 text-start">{category.name}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-muted">No categories selected.</p>
              )}
              <p className="text-muted fs-6 lead text-end border-bottom">Your Meal Plan at a Glance</p>
              <div className="list-group">
                <div className="list-group-item d-flex justify-content-between align-items-center border-0 bg-transparent">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-person me-2" style={{ fontSize: '1.2rem' }} />
                    <span className="text-start">Number of People</span>
                  </div>
                  <span>{stepData.selectedPeople}</span>
                </div>
                <div className="list-group-item d-flex justify-content-between align-items-center border-0 bg-transparent">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-utensils me-2" style={{ fontSize: '1.2rem' }} />
                    <span className="text-start">Meals per Week</span>
                  </div>
                  <span>{stepData.selectedMeals}</span>
                </div>
                <div className="list-group-item d-flex justify-content-between align-items-center border-0 bg-transparent">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-list-ol me-2" style={{ fontSize: '1.2rem' }} />
                    <span className="text-start">Total Servings</span>
                  </div>
                  <span>{stepData.totalServings}</span>
                </div>
                <div className="list-group-item d-flex justify-content-between align-items-center border-0 bg-transparent">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-cash me-2" style={{ fontSize: '1.2rem' }} />
                    <span className="text-start">First Box Total</span>
                  </div>
                  <span>${stepData.firstBoxTotal}</span>
                </div>
              </div>
              {!isAuthenticated && (
                <Button
                  className="w-100 btn-lg mt-4 btn-orange"
                  onClick={() => loginWithRedirect({
                    screen_hint: "signup",
                    appState: { returnTo: "/ruokaohjelma?step=2" }
                  })}
                >
                  Sign Up Now
                </Button>
              )}
              <hr className="my-4" />
              <small className="text-muted">By clicking Sign Up, you agree to our terms of use.</small>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SignupStep;

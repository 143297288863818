import React from 'react';
import './PeopleSelectorToolbar.css'; // Custom CSS styles for the toolbar
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const PeopleSelectorToolbar = ({ selectedPeople, onSelect, title, options }) => {
  // Tooltip description for people selection
  const renderTooltip = (props, size) => (
    <Tooltip id={`tooltip-${size}`} {...props}>
      {`Select for ${size} person${size > 1 ? 's' : ''}.`}
    </Tooltip>
  );

  return (
    <div className="d-flex flex-column align-items-center">
      <p className="me-3 fw-bold">{title}</p> {/* Toolbar Title */}
      <p className="text-muted text-center mb-3">
        Choose the number of people to customize your meal plan.
      </p>
      <div className="btn-group border" role="group" aria-label={`${title} toolbar`}>
        {options.map((size) => (
          <OverlayTrigger
            key={size}
            placement="top"
            overlay={(props) => renderTooltip(props, size)}
          >
            <button
              type="button"
              className={`btn ${selectedPeople === size ? 'btn-orange' : 'btn-outline-orange'}`} // Conditional styling based on selection
              onClick={() => onSelect(size)} // Call the onSelect function with the selected size
              aria-pressed={selectedPeople === size} // Indicates if the button is currently pressed
            >
              {size} {/* Display the size */}
            </button>
          </OverlayTrigger>
        ))}
      </div>
    </div>
  );
};

export default PeopleSelectorToolbar;

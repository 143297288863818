import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Tietoja() {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Tietoja</h1>
          <p>Learn more about our company and our mission.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Tietoja;
import React from 'react';

const Footer = () => {
  return (
    <div className="container border-top ">
      <footer className="py-5">
        <div className="row">
          <div className="col-6 col-md-2 mb-3">
            <h5>Meal Kits</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Mediterranean</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Keto</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Vegan</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Paleo</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Whole30</a></li>
            </ul>
          </div>

          <div className="col-6 col-md-2 mb-3">
            <h5>Resources</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Blog</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Recipes</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Guides</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Support</a></li>
            </ul>
          </div>

          <div className="col-6 col-md-2 mb-3">
            <h5>Company</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About Us</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Careers</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Press</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Contact</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Privacy Policy</a></li>
            </ul>
          </div>

          <div className="col-md-5 offset-md-1 mb-3">
            <form>
              <h5>Subscribe to our newsletter</h5>
              <p>Get the latest updates on new meal kits and exclusive offers.</p>
              <div className="d-flex w-100 gap-2">
                <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                <button className="btn btn-primary" type="button">Subscribe</button>
              </div>
            </form>
          </div>
        </div>

        <div className="d-flex justify-content-between py-4 my-4 border-top">
          <p>&copy; 2023 Meal Kit Company, Inc. All rights reserved.</p>
          <ul className="list-unstyled d-flex">
            <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#twitter" /></svg></a></li>
            <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#instagram" /></svg></a></li>
            <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#facebook" /></svg></a></li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
import React from 'react';
import './Loading.css'; // Import the CSS file for styling

function Loading({ loading }) {
  return (
    <>
      {loading ? (
        <div className="loadingspinner">
          <div id="square1"></div>
          <div id="square2"></div>
          <div id="square3"></div>
          <div id="square4"></div>
          <div id="square5"></div>
        </div>
      ) : (
        <div className="loading-message">Loading took too long. Please try again later.</div>
      )}
    </>
  );
}

export default Loading;

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CategoryCards from "./CategoryCards/CategoryCards";
import PeopleSelectorToolbar from "./PeopleSelectorToolbar/PeopleSelectorToolbar";
import MealsPerWeekToolbar from "./MealsPerWeekToolbar/MealsPerWeekToolbar";
import PriceSelectionStep from "./PricePerServing/PricePerServing";
import "./PlanSelectionStep.css";

// Import the other component you want to pass the data to

const PlanSelectionStep = ({ onStepData }) => {
  // State Variables
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState(2); // Default value for meals
  const [selectedPeople, setSelectedPeople] = useState(4); // Default value for people
  const [showThreePeopleOption, setShowThreePeopleOption] = useState(false); // Manage visibility of 3 people option
  const [pricePerServing] = useState(11.2); // Example price per serving

  // Titles and Descriptions
  const dietaryTitle = "1. Choose Your Dietary Preferences"; 
  const dietaryDescription = "Select your dietary preferences from the options below."; 
  const mealBoxTitle = "2. Customize Your Meal Box"; 
  const mealBoxDescription = "Choose the number of people and meals per week to personalize your meal plan."; 

  // Effect to load data from localStorage on mount
  useEffect(() => {
    const savedCategories = JSON.parse(localStorage.getItem("selectedCategories")) || [];
    const savedPeople = JSON.parse(localStorage.getItem("selectedPeople")) || 4;
    const savedMeals = JSON.parse(localStorage.getItem("selectedMeals")) || 2;

    setSelectedCategories(savedCategories);
    setSelectedPeople(savedPeople);
    setShowThreePeopleOption(savedPeople === 4);
    setSelectedMeals(savedMeals);

    // Update the data for parent
    updateStepData(savedCategories, savedPeople, savedMeals);
  }, []);

  // Function to package and pass step data
  const updateStepData = (categories, people, meals) => {
    const totalServings = meals * people;
    const firstBoxTotal = (totalServings * pricePerServing).toFixed(2);

    const packagedData = {
      selectedCategories: categories,
      selectedPeople: people,
      selectedMeals: meals,
      firstBoxTotal,
      totalServings,
    };

    // Pass data to parent component
    onStepData(packagedData);
  };

  // Handle category selection
  const handleSelectCategories = (categories) => {
    setSelectedCategories(categories);
    localStorage.setItem("selectedCategories", JSON.stringify(categories));
    updateStepData(categories, selectedPeople, selectedMeals);
  };

  // Handle people selection
  const handlePeopleSelect = (people) => {
    setSelectedPeople(people);
    setShowThreePeopleOption(people === 4);
    localStorage.setItem("selectedPeople", JSON.stringify(people));
    updateStepData(selectedCategories, people, selectedMeals);
  };

  // Handle meal selection
  const handleMealsSelect = (meals) => {
    setSelectedMeals(meals);
    localStorage.setItem("selectedMeals", JSON.stringify(meals));
    updateStepData(selectedCategories, selectedPeople, meals);
  };

  return (
    <Row className="pt-3">
      {/* Dietary Preferences Section */}
      <Col lg={6} className="d-flex flex-column align-items-center mb-4">
        <h5 className="text-center fw-bold">{dietaryTitle}</h5>
        <p className="text-center mb-4 text-muted">{dietaryDescription}</p>
        <CategoryCards 
          selectedCategories={selectedCategories} 
          onSelectCategories={handleSelectCategories} 
        />
        <div className="row w-100 mt-2">
          <p className="fw-bold text-center">Categories of your choice</p>
          {selectedCategories.length > 0 ? (
            <div className="d-flex flex-wrap justify-content-center">
              {selectedCategories.map((category, index) => (
                <div key={index} className="badge border text-black d-flex align-items-center p-2 rounded-3 m-1">
                  <i className={`bi ${category.icon} me-1`} style={{ fontSize: '1rem' }}></i>
                  <span className="small">{category.name}</span>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-muted text-center">No categories selected yet.</p>
          )}
        </div>
      </Col>

      {/* Meal Box Customization Section */}
      <Col lg={6} className="d-flex flex-column align-items-center h-100">
        <h5 className="text-center fw-bold">{mealBoxTitle}</h5>
        <p className="text-center mb-4 text-muted">{mealBoxDescription}</p>
        
        <Row className="w-100">
          <Col lg={12}>
            <PeopleSelectorToolbar
              selectedPeople={selectedPeople}
              onSelect={handlePeopleSelect}
              title="Select Number of People"
              options={[2, 4, 6]}
            />
          </Col>
          <Col lg={12}>
            <MealsPerWeekToolbar
              selectedMeals={selectedMeals}
              onSelect={handleMealsSelect}
              title="Select Meals per Week"
              options={[2, 3, 4]}
              selectedPeople={selectedPeople}
            />
          </Col>
        </Row>

        {/* Price Display */}
        <div className="row mt-4">
          <PriceSelectionStep 
            selectedMeals={selectedMeals} 
            selectedPeople={selectedPeople} 
            pricePerServing={pricePerServing}
          />
        </div>
      </Col>

      
    </Row>
  );
};

export default PlanSelectionStep;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

function FAQSection() {
  return (
    <section className="container pt-4">
      <h2 className="fw-bold mb-4 ps-4 text-center">Usein kysytyt kysymykset</h2>
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              <strong>Miten Chefboksi toimittaa?</strong>
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body text-black">
              Chefboksi kuljetetaan kotiovelle kylmäketjusta huolehtien erityisellä kylmäautolla, ympäristöystävällisesti ja järkevällä reittisuunnitelmalla. Toimituspäivät ovat tiistai. Saat valita sopivimman toimituspäivän kun lisäät tuotteen ostoskoriin. Toimitus saapuu valittuna päivänä klo 16-19 aikaikkunassa. Toimituspäivän aamuna saat vielä tarkemman aika-arvion yhden tunnin tarkkuudella.
            </div>
          </div>
        </div>
        {/* Add more accordion items here if needed */}
      </div>
      <div className="text-center mt-3">
        <a className="btn btn-custom btn-md my-2 mx-2 btn-rounded" href="index.php?component=faqs">Lue lisää</a>
      </div>
    </section>
  );
}

export default FAQSection;
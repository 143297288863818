import React, { useEffect } from 'react';
import './MealsPerWeekToolbar.css'; // Custom CSS for the toolbar
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const MealsPerWeekToolbar = ({ selectedMeals, onSelect, title, options, selectedPeople }) => {
  // Tooltip description for meal selection
  const renderTooltip = (props, count) => (
    <Tooltip id={`tooltip-${count}`} {...props}>
      {`Select ${count} meal${count > 1 ? 's' : ''} per week.`}
    </Tooltip>
  );

  // Effect to handle disabling selection when button "3" is hidden
  useEffect(() => {
    if (selectedPeople !== 4 && selectedMeals === 3) {
      // Reset to a default meal selection when 3 meals is not valid
      onSelect(2);
    }
  }, [selectedPeople, selectedMeals, onSelect]);

  return (
    <div className="d-flex flex-column align-items-center">
      <p className="me-3 fw-bold">{title}</p> {/* Toolbar Title */}
      <p className="text-muted text-center mb-3">
        Choose the number of meals you would like to receive each week.
      </p>
      <div className="btn-group border" role="group" aria-label={`${title} toolbar`}>
        {options
          .filter((count) => !(selectedPeople !== 4 && count === 3)) // Hide "3" if selectedPeople is not 4
          .map((count) => (
            <OverlayTrigger
              key={count}
              placement="top"
              overlay={(props) => renderTooltip(props, count)}
            >
              <button
                type="button"
                className={`btn ${selectedMeals === count ? 'btn-orange' : 'btn-outline-orange'}`} // Conditional styling based on selection
                onClick={() => {
                  if (count !== 3 || selectedPeople === 4) {
                    onSelect(count);
                  }
                }} // Call the onSelect function with the selected count, but disable the click for 3 meals when not applicable
                aria-pressed={selectedMeals === count} // Indicates if the button is currently pressed
                disabled={selectedPeople !== 4 && count === 3} // Disable the button for 3 meals if selectedPeople is not 4
              >
                {count} {/* Display the count */}
              </button>
            </OverlayTrigger>
          ))}
      </div>
    </div>
  );
};

export default MealsPerWeekToolbar;

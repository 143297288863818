import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Lahjakorit() {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Lahjakorit</h1>
          <p>Explore our gift baskets and find the perfect gift for your loved ones.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Lahjakorit;
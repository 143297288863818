import React from 'react';
import { useNavigate } from 'react-router-dom';
import MealPlanStepper from '../components/MealPlanStepper/MealPlanStepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import CardCarousel from '../components/CardCarousel/CardCarousel';
import './RuokaohjelmaPage.css';
function RuokaohjelmaPage() {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  const RuokaohjelmaPageHeading = 'Create Your Diet Plan';
  const RuokaohjelmaPageDescription = 'Follow the steps to create a personalized diet plan tailored to your needs.';

  return (
    <>
    <div className="container my-4">
      <div className="row ">
        {/* Left Column for Heading and Description */}
        <div className="col-lg-9 col-sm-8 col-md-7 col-9 text-center">
          <h2 className="mb-3  fs-sm-1">{RuokaohjelmaPageHeading}</h2>

        </div>

        {/* Right Column for Back to Home Button */}
        <div className="col-lg-3 col-md-5 col-sm-4 text-lg-end col-2 ">
          <button
            className="btn btn-orange float-end d-flex align-items-center justify-content-center"
            onClick={handleBackToHome}
          >
            <FontAwesomeIcon icon={faHome} className="me-2" />
            <span className='d-none d-sm-inline'>Back to Home</span>
          </button>
        </div>
      </div>
        <MealPlanStepper
   
        />
      
    </div>
      <CardCarousel
           SectionHeading = "Enjoy 50+ options each week"
        SectionSubheading = "for breakfast, lunch, dinner and more. Simply select recipes after checkout or view our weekly menus"
      />
    </>

  );
}

export default RuokaohjelmaPage;

import React from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './Loading/Loading';

const ProtectedRoute = ({ component, roles, ...args }) => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    // Show a loading component or message while authentication is in progress
    return <Loading />;
  }

  // Access roles from custom claim
  const namespace = 'http://localhost:3000/';
  const userRoles = (user && user[`${namespace}roles`]) || [];

  if (roles && !roles.some(role => userRoles.includes(role))) {
    return <div>You do not have access to this page.</div>;
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component {...args} />;
};

export default ProtectedRoute;

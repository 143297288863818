import React from 'react';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../LoginButton/LoginButton';
import LogoutButton from '../LogoutButton/LogoutButton';
import './Navbar.css';
import Logo from '../../assets/images/Logo/logo.png';

function AppNavbar() {
  const { isAuthenticated, user } = useAuth0();
  const roles = (user && user['http://localhost:3000/roles']) || [];

  return (
    <Navbar bg="light" expand="lg" className="rounded sticky-top">
      <Navbar.Brand as={NavLink} to="/">
        <img
          src={Logo}
          alt="Logo"
          width="70"
          height="70"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav" className="justify-content-center">
        <Nav className="mr-auto">
          {roles.includes('admin') && (
            <NavLink
              to="/admin"
              className={({ isActive }) => (isActive ? 'active-navlink nav-link' : 'nav-link')}
            >
              Admin
            </NavLink>
          )}
          <NavLink
            to="/weekly-menu"
            className={({ isActive }) => (isActive ? 'active-navlink nav-link' : 'nav-link')}
          >
            Viikkomenut
          </NavLink>
          <NavLink
            to="/ruokaohjelma"
            className={({ isActive }) => (isActive ? 'active-navlink nav-link' : 'nav-link')}
          >
            Ruokaohjelma
          </NavLink>
          <NavLink
            to="/how-it-works"
            className={({ isActive }) => (isActive ? 'active-navlink nav-link' : 'nav-link')}
          >
            How It Works
          </NavLink>
          <NavLink
            to="/lahjakorit"
            className={({ isActive }) => (isActive ? 'active-navlink nav-link' : 'nav-link')}
          >
            Lahjakorit
          </NavLink>
          <NavLink
            to="/ravitsemusvalmennus"
            className={({ isActive }) => (isActive ? 'active-navlink nav-link' : 'nav-link')}
          >
            Ravitsemusvalmennus
          </NavLink>
          <NavLink
            to="/tietoja"
            className={({ isActive }) => (isActive ? 'active-navlink nav-link' : 'nav-link')}
          >
            Tietoja
          </NavLink>
        </Nav>
        <Nav>
          {isAuthenticated ? (
            <Dropdown >
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img
                  src={user.picture}
                  alt="User Avatar"
                  width="40"
                  height="40"
                  className="rounded-circle"
                />{' '}
                {user.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={NavLink} to="/profile">
                  Profile
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/settings">
                  Settings
                </Dropdown.Item>
                <Dropdown.Item>
                  <LogoutButton />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <LoginButton />
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default AppNavbar;

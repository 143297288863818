import React, { useRef } from "react";
import "./CardCarousel.css"; // Assuming this file contains necessary styles for the carousel
import { Card, Col, Row } from "react-bootstrap";

// Array of card items
const cardItems = [
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(4).jpg",
    title: "Mediterranean Diet",
    subtitle: "Heart-Healthy Choices",
    description:
      "Focuses on whole grains, fruits, vegetables, and healthy fats.",
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(1).jpg",
    title: "Keto Diet",
    subtitle: "Low-Carb Goodness",
    description: "High-fat, low-carb diet to help your body enter ketosis.",
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(7).jpg",
    title: "Vegan Diet",
    subtitle: "Plant-Based Nutrition",
    description: "Excludes all animal products for a plant-based lifestyle.",
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(2).jpg",
    title: "Paleo Diet",
    subtitle: "Eat Like a Caveman",
    description:
      "Focuses on unprocessed foods like lean meats, fish, fruits, and vegetables.",
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(3).jpg",
    title: "Whole30 Diet",
    subtitle: "30 Days of Clean Eating",
    description:
      "A 30-day elimination diet focusing on whole foods and avoiding sugar, grains, and dairy.",
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(5).jpg",
    title: "DASH Diet",
    subtitle: "Dietary Approaches to Stop Hypertension",
    description:
      "Designed to combat high blood pressure with a focus on fruits, vegetables, and low-fat dairy.",
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(6).jpg",
    title: "Intermittent Fasting",
    subtitle: "Timing Your Meals",
    description:
      "Cycles between periods of eating and fasting to manage weight and health.",
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(8).jpg",
    title: "Low-FODMAP Diet",
    subtitle: "For Digestive Health",
    description:
      "Reduces certain carbohydrates that are poorly absorbed in the small intestine.",
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(9).jpg",
    title: "Gluten-Free Diet",
    subtitle: "Avoiding Gluten",
    description:
      "Excludes gluten to manage celiac disease or gluten sensitivity.",
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Square/img(10).jpg",
    title: "Flexitarian Diet",
    subtitle: "Mostly Vegetarian with Flexibility",
    description: "Primarily plant-based but includes occasional meat and fish.",
  },
];

// CardCarousel Component
const CardCarousel = ({ SectionHeading, SectionSubheading }) => {
  const rowRef = useRef(null);

  // Functions to handle scroll for the carousel
  const scrollLeft = () => {
    const scrollAmount = window.innerWidth < 768 ? -150 : -300; // Adjust scroll for small screens
    rowRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  const scrollRight = () => {
    const scrollAmount = window.innerWidth < 768 ? 150 : 300; // Adjust scroll for small screens
    rowRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  return (
    <div className="container p-2">
      {/* Section Title and Subtitle */}
      <h2 className="text-center mb-4">{SectionHeading}</h2>
      <h5 className="text-center mb-4">{SectionSubheading}</h5>

      {/* Carousel container with scroll arrows */}
      <div className="carousel-container position-relative">
        {/* Left arrow */}
        <button
          className="carousel-arrow left btn  btn-floating text-white shadow position-absolute d-none d-md-block"
          onClick={scrollLeft}
        >
          &#9664;
        </button>

        {/* Right arrow */}
        <button
          className="carousel-arrow right btn  btn-floating text-white shadow position-absolute d-none d-md-block"
          onClick={scrollRight}
        >
          &#9654;
        </button>

        {/* Card Carousel */}
        <div className="carousel-wrapper overflow-hidden p-5">
          <Row
            className="cards-row d-flex overflow-hidden flex-nowrap row row-cols-2 m-0 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 g-0"
            ref={rowRef}
          >
            {cardItems.map((item, idx) => (
              <Col key={idx} className="col">
                <Card className="card-item shadow-sm h-100">
                  <Card.Img variant="top" src={item.image} />
                  <Card.Body className="h-100">
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {item.subtitle}
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <div className="text-center justify-content-center">
          <button
            className="carousel-arrow-left btn text-white d-md-none mx-2"
            onClick={scrollLeft}
          >
            &#9664;
          </button>
          <button
            className="carousel-arrow-right btn text-white d-md-none mx-2"
            onClick={scrollRight}
          >
            &#9654;
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardCarousel;
